import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { addGuidancePageItemIndices, GuidancePage } from 'models/guidancePage';
import { createAction } from 'ducks/actionHelpers';
import { REDEEM_COUPON_SUCCESS, redeemCouponSuccess } from 'ducks/client/coupons';

const FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_REQUEST =
  'FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_REQUEST';
const FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_SUCCESS =
  'FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_SUCCESS';
const FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_FAILURE =
  'FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_FAILURE';

const fetchDigitalGuidancePageRequest = () =>
  createAction(FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_REQUEST);
const fetchDigitalGuidancePageSuccess = (response: GuidancePage[]) =>
  createAction(FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_SUCCESS, response);
const fetchDigitalGuidancePageFailure = (err: string) =>
  createAction(FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_FAILURE, err);

export const fetchReservationDigitalGuidancePages = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalGuidancePageRequest());

  const path = `${config.apiUrl}/reservations/${reservationId}/guidancepages`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchDigitalGuidancePageSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchDigitalGuidancePageFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchDigitalGuidancePageRequest>
  | ReturnType<typeof fetchDigitalGuidancePageSuccess>
  | ReturnType<typeof fetchDigitalGuidancePageFailure>
  | ReturnType<typeof redeemCouponSuccess>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_FAILURE:
      return action.payload;
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_REQUEST:
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const pages = (state: GuidancePage[] | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_SUCCESS:
      return action.payload?.map((page) => addGuidancePageItemIndices(page));
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_PAGE_FAILURE:
      return null;
    case REDEEM_COUPON_SUCCESS: {
      const couponId = action.payload.coupon_id;

      return state?.map((page) => {
        if (page.items?.some((item) => item.coupons?.some((coupon) => coupon.id === couponId))) {
          return {
            ...page,
            items: page.items?.map((item) => ({
              ...item,
              coupons: item.coupons?.map((coupon) => ({
                ...coupon,
                redeemed: coupon.redeemed || coupon.id === couponId,
              })),
            })),
          };
        } else {
          return page;
        }
      });
    }
    default:
      return state;
  }
};

export interface ReservationDigitalGuidancePagesState {
  error: ReturnType<typeof error>;
  pages: ReturnType<typeof pages>;
}

export default combineReducers({
  error,
  pages,
});
