export interface FeatureFlags {
  // Sample feature flag keeps compiler warnings at bay when no other feature flags are active.
  sampleFeatureFlag: boolean;
  enableProductSalesStatus: boolean;
  enableResourceManagement: boolean;
  enableETicket: boolean;
  enableStampRally: boolean;
  enableHideTooManyServices: boolean;
  enableBookInDigitalGuidance: boolean;
  enableQRCheckinEnhancements: boolean;
  enableCustomerLedger: boolean;
  enableETicketOptionQRCode: boolean;
  enableETicketFraudDetection: boolean;
  enableCustomTopPage: boolean;
  enableAvailabilitySearch: boolean;
  enableSeatManagement: boolean;
  enableFootprintedModal: boolean;
}

// In "development" mode, all features including those currently under development are enabled.
export const developmentFeatureFlags: FeatureFlags = {
  sampleFeatureFlag: true,
  enableProductSalesStatus: true,
  enableResourceManagement: true,
  enableETicket: true,
  enableStampRally: true,
  enableHideTooManyServices: true,
  enableBookInDigitalGuidance: true,
  enableQRCheckinEnhancements: true,
  enableCustomerLedger: true,
  enableETicketOptionQRCode: true,
  enableETicketFraudDetection: true,
  enableCustomTopPage: true,
  enableAvailabilitySearch: true,
  enableSeatManagement: true,
  enableFootprintedModal: true,
};

// In "release" mode, only features ready for production release are enabled.
export const releaseFeatureFlags: FeatureFlags = {
  sampleFeatureFlag: false,
  enableProductSalesStatus: true,
  enableResourceManagement: true,
  enableETicket: true,
  enableStampRally: true,
  enableHideTooManyServices: true,
  enableBookInDigitalGuidance: true,
  enableQRCheckinEnhancements: true,
  enableCustomerLedger: false,
  enableETicketOptionQRCode: false,
  enableETicketFraudDetection: false,
  enableCustomTopPage: false,
  enableAvailabilitySearch: false,
  enableSeatManagement: false,
  enableFootprintedModal: true,
};

// stg feature flags are not ready for release but are enabled for demo purposes in the stg environment.
// This breaks our "production is the same as staging" testing so it should be used sparingly.
export const stgDemoFeatureFlags: Partial<FeatureFlags> = {
  enableResourceManagement: true,
  enableStampRally: true,
  enableHideTooManyServices: true,
  enableBookInDigitalGuidance: true,
  enableQRCheckinEnhancements: true,
  enableCustomerLedger: true,
  enableETicketOptionQRCode: true,
  enableFootprintedModal: true,
};
