import { AnyAction, combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import settings, { fetchSettings, SettingsState } from 'ducks/server/settings';
import product, { fetchProduct, ProductState } from 'ducks/server/product';
import productInstance, {
  fetchProductInstance,
  ProductInstanceState,
} from 'ducks/server/productInstance';
import productSummaries, {
  fetchProductSummaries,
  ProductSummariesState,
} from 'ducks/server/productSummaries';
import reservation, { fetchReservation, ReservationState } from 'ducks/server/reservation';
import promotion, { fetchPromotion, PromotionState } from 'ducks/server/promotion';
import customPages, { fetchCustomPages, CustomPagesState } from 'ducks/server/customPages';
import waiverTemplates, {
  fetchWaiverTemplateForReservation,
  fetchWaiverTemplate,
  WaiverTemplatesState,
} from 'ducks/server/waiverTemplates';
import waivers, { fetchWaiver, fetchWaivers, WaiversState } from 'ducks/server/waivers';
import reservationVoucher, {
  fetchReservationVoucher,
  ReservationVoucherState,
} from 'ducks/server/reservationVoucher';
import mediaDownloadSalesPage, {
  fetchMediaDownloadSalesPageForReservation,
  MediaDownloadSalesPageState,
} from 'ducks/server/mediaDownloadSalesPage';
import mediaDownloadOrder, {
  fetchMediaDownloadOrder,
  MediaDownloadOrderState,
} from 'ducks/server/mediaDownloadOrder';
import digitalGuidancePage, {
  fetchDigitalGuidancePage,
  DigitalGuidancePageState,
} from './server/digitalGuidancePage';
import reservationDigitalGuidancePages, {
  fetchReservationDigitalGuidancePages,
  ReservationDigitalGuidancePagesState,
} from './server/reservationDigitalGuidancePages';
import surveyTemplates, {
  fetchSurveyTemplate,
  fetchReservationSurveyTemplate,
  SurveyTemplatesState,
} from 'ducks/server/surveyTemplates';
import reservationDigitalGuidanceStampRallies, {
  fetchReservationDigitalGuidanceStampRallies,
  ReservationDigitalGuidanceStampRalliesState,
} from './server/reservationDigitalGuidanceStampRallies';
import digitalGuidanceStampRally, {
  fetchDigitalGuidanceStampRally,
  DigitalGuidanceStampRallyState,
} from './server/digitalGuidanceStampRally';
import eTicketQrCode, { fetchETicketQrCode, ETicketQrCodeState } from './server/eTicketQrCode';

export type ServerState = {
  settings: SettingsState;
  productSummaries: ProductSummariesState;
  product: ProductState;
  productInstance: ProductInstanceState;
  reservation: ReservationState;
  reservationDigitalGuidancePages: ReservationDigitalGuidancePagesState;
  promotion: PromotionState;
  customPages: CustomPagesState;
  waiverTemplates: WaiverTemplatesState;
  waivers: WaiversState;
  reservationVoucher: ReservationVoucherState;
  mediaDownloadSalesPage: MediaDownloadSalesPageState;
  mediaDownloadOrder: MediaDownloadOrderState;
  digitalGuidancePage: DigitalGuidancePageState;
  surveyTemplates: SurveyTemplatesState;
  reservationDigitalGuidanceStampRallies: ReservationDigitalGuidanceStampRalliesState;
  digitalGuidanceStampRally: DigitalGuidanceStampRallyState;
  eTicketQrCode: ETicketQrCodeState;
};

// Alias server side fetches so callers can be explicit
export const fetchSettingsServerSide = fetchSettings;
export const fetchProductSummariesServerSide = fetchProductSummaries;
export const fetchProductServerSide = fetchProduct;
export const fetchProductInstanceServerSide = fetchProductInstance;
export const fetchReservationServerSide = fetchReservation;
export const fetchReservationDigitalGuidancePagesServerSide = fetchReservationDigitalGuidancePages;
export const fetchPromotionServerSide = fetchPromotion;
export const fetchCustomPagesServerSide = fetchCustomPages;
export const fetchWaiverTemplateForReservationServerSide = fetchWaiverTemplateForReservation;
export const fetchWaiverTemplateServerSide = fetchWaiverTemplate;
export const fetchWaiverServerSide = fetchWaiver;
export const fetchWaiversServerSide = fetchWaivers;
export const fetchReservationVoucherServerSide = fetchReservationVoucher;
export const fetchMediaDownloadSalesPageForReservationServerSide = fetchMediaDownloadSalesPageForReservation;
export const fetchMediaDownloadOrderServerSide = fetchMediaDownloadOrder;
export const fetchDigitalGuidancePageServerSide = fetchDigitalGuidancePage;
export const fetchSurveyTemplateServerSide = fetchSurveyTemplate;
export const fetchReservationSurveyTemplateServerSide = fetchReservationSurveyTemplate;
export const fetchReservationDigitalGuidanceStampRalliesServerSide = fetchReservationDigitalGuidanceStampRallies;
export const fetchDigitalGuidanceStampRallyServerSide = fetchDigitalGuidanceStampRally;
export const fetchETicketQrCodeServerSide = fetchETicketQrCode;

const reducer = combineReducers({
  digitalGuidancePage,
  mediaDownloadOrder,
  mediaDownloadSalesPage,
  product,
  productInstance,
  productSummaries,
  reservation,
  reservationDigitalGuidancePages,
  reservationVoucher,
  promotion,
  settings,
  customPages,
  surveyTemplates,
  waiverTemplates,
  waivers,
  reservationDigitalGuidanceStampRallies,
  digitalGuidanceStampRally,
  eTicketQrCode,
});

const server = (state: ServerState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.server,
      };
    default:
      return reducer(state, action);
  }
};

export default server;
