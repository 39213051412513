import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { SubmitSurveyRequest, SurveySubmission } from 'models/survey';

// Actions

const SUBMIT_SURVEY_REQUEST = 'SUBMIT_SURVEY_REQUEST';
const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
const SUBMIT_SURVEY_FAILURE = 'SUBMIT_SURVEY_FAILURE';

// Action creators

const submitSurveyRequest = () => createAction(SUBMIT_SURVEY_REQUEST);
const submitSurveySuccess = (payload: SurveySubmission) =>
  createAction(SUBMIT_SURVEY_SUCCESS, payload);
const submitSurveyFailure = (payload: string) => createAction(SUBMIT_SURVEY_FAILURE, payload);

export const submitSurvey = (apiKey: string, req: SubmitSurveyRequest) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(submitSurveyRequest());
  return axios
    .post(`${config.apiUrl}/surveysubmissions`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(submitSurveySuccess(response.data));
    })
    .catch((err) => {
      dispatch(submitSurveyFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof submitSurveyRequest>
  | ReturnType<typeof submitSurveySuccess>
  | ReturnType<typeof submitSurveyFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case SUBMIT_SURVEY_FAILURE:
      return action.payload;
    case SUBMIT_SURVEY_REQUEST:
    case SUBMIT_SURVEY_SUCCESS:
      return '';
    default:
      return state;
  }
};

const inFlight = (state = false, action: Action) => {
  switch (action.type) {
    case SUBMIT_SURVEY_REQUEST:
      return true;
    case SUBMIT_SURVEY_SUCCESS:
    case SUBMIT_SURVEY_FAILURE:
      return false;
    default:
      return state;
  }
};

export interface SurveysState {
  error: ReturnType<typeof error>;
  inFlight: ReturnType<typeof inFlight>;
}

export default combineReducers({
  error,
  inFlight,
});
