import axios from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { ProductInstance } from 'models/productInstance';
import { createAction } from 'ducks/actionHelpers';

// Actions
const RE_FETCH_PRODUCT_INSTANCE_REQUEST = 'RE_FETCH_PRODUCT_INSTANCE_REQUEST';
export const RE_FETCH_PRODUCT_INSTANCE_SUCCESS = 'RE_FETCH_PRODUCT_INSTANCE_SUCCESS';
const RE_FETCH_PRODUCT_INSTANCE_FAILURE = 'RE_FETCH_PRODUCT_INSTANCE_FAILURE';

// Action creators
const reFetchProductInstanceRequest = () => createAction(RE_FETCH_PRODUCT_INSTANCE_REQUEST);
export const reFetchProductInstanceSuccess = (response: ProductInstance) =>
  createAction(RE_FETCH_PRODUCT_INSTANCE_SUCCESS, response);
const reFetchProductInstanceFailure = (err: string) =>
  createAction(RE_FETCH_PRODUCT_INSTANCE_FAILURE, err);

export const reFetchProductInstance = (
  apiKey: string,
  id: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(reFetchProductInstanceRequest());
  return axios
    .get(`${config.apiUrl}/products/-/instances/${id}?include_resource_availability=true`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(reFetchProductInstanceSuccess({ ...response.data, isReFetched: true }));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(reFetchProductInstanceFailure('canceled'));
      } else {
        dispatch(reFetchProductInstanceFailure(err.message));
      }
    });
};
