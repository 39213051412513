import * as React from 'react';
import { useSelector } from 'react-redux';

import config from 'config';
import {
  CustomerTokenContextType,
  CustomerTokenType,
  CustomerTokenContext,
} from 'contexts/CustomerTokenContext';
import {
  loadCognitoOAuth,
  clearCognitoOAuth,
  storeCognitoOAuth,
} from 'lib/manageCognitoOAuthLocalStorage';
import { IdProvider } from 'models/customer';
import { ReduxState } from 'ducks';

export const CustomerTokenProvider = ({ children }: { children: React.ReactNode }) => {
  const [idToken, setIdToken] = React.useState<string>('');
  const [accessToken, setAccessToken] = React.useState<string>('');
  const [refreshToken, setRefreshToken] = React.useState<string>('');
  const [expiresAt, setExpiresAt] = React.useState<number>(0);
  const [idProvider, setIdProvider] = React.useState<IdProvider | null>(null);
  const [apiKey, setApiKey] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);

  const customerToken = useSelector((state: ReduxState) => state.customer.customerToken);

  React.useEffect(() => {
    if (customerToken) {
      const newToken = {
        idToken: customerToken.id_token ?? '',
        accessToken: customerToken.access_token ?? '',
        refreshToken: customerToken.refresh_token ?? '',
        expiresAt: customerToken.expires_date_time_utc ?? 0,
        idProvider: customerToken.id_provider ?? null,
        apiKey: customerToken.api_key ?? '',
      };
      setToken(newToken);
    }
  }, [customerToken]);

  const logout = () => {
    clearCognitoOAuth();
    setIdToken('');
    setAccessToken('');
    setRefreshToken('');
    setExpiresAt(0);
    setIdProvider(null);
    setApiKey('');
  };

  const setToken = (props: CustomerTokenType) => {
    storeCognitoOAuth(props);
    setIdToken(props.idToken ?? '');
    setAccessToken(props.accessToken ?? '');
    setRefreshToken(props.refreshToken ?? '');
    setExpiresAt(props.expiresAt ?? 0);
    setIdProvider(props.idProvider ?? null);
    setApiKey(props.apiKey ?? '');
    setLoading(false);
  };

  React.useEffect(() => {
    if (config.enableCustomerLedger) {
      loadCognitoOAuth(setToken);
    }
  }, []);

  const value: CustomerTokenContextType = {
    idToken,
    accessToken,
    refreshToken,
    expiresAt,
    idProvider,
    logout,
    setToken,
    apiKey,
    loading,
  };

  return <CustomerTokenContext.Provider value={value}>{children}</CustomerTokenContext.Provider>;
};
