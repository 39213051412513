import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { RedeemCouponRequest } from 'models/guidancePage';

// Actions
const REDEEM_COUPON_REQUEST = 'REDEEM_COUPON_REQUEST';
export const REDEEM_COUPON_SUCCESS = 'REDEEM_COUPON_SUCCESS';
const REDEEM_COUPON_FAILURE = 'REDEEM_COUPON_FAILURE';

// Action creators
const redeemCouponRequest = (payload: RedeemCouponRequest) =>
  createAction(REDEEM_COUPON_REQUEST, payload);
export const redeemCouponSuccess = (payload: RedeemCouponRequest) =>
  createAction(REDEEM_COUPON_SUCCESS, payload);
const redeemCouponFailure = (payload: string) => createAction(REDEEM_COUPON_FAILURE, payload);

export const redeemCoupon = (apiKey: string, req: RedeemCouponRequest) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): void => {
  dispatch(redeemCouponRequest(req));
  axios
    .post(`${config.apiUrl}/reservations/${req.reservation_id}/redeemcoupon`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then(() => {
      dispatch(redeemCouponSuccess(req));
    })
    .catch((err) => {
      dispatch(redeemCouponFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof redeemCouponRequest>
  | ReturnType<typeof redeemCouponSuccess>
  | ReturnType<typeof redeemCouponFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case REDEEM_COUPON_FAILURE:
      return action.payload;
    case REDEEM_COUPON_REQUEST:
    case REDEEM_COUPON_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case REDEEM_COUPON_REQUEST:
      return true;
    case REDEEM_COUPON_SUCCESS:
    case REDEEM_COUPON_FAILURE:
      return false;
    default:
      return state;
  }
};

export interface CouponsState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
}

export default combineReducers({
  error,
  loading,
});
