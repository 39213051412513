import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import config from 'config';
import { ReduxState } from 'ducks';
import { createAction } from 'ducks/actionHelpers';
import { languageOptionMap } from 'lib/util/i18nHelpers';
import { ContentLanguage, GetSettingsResponse } from 'models/settings';

// Actions
const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
const SETTINGS_FAILURE = 'SETTINGS_FAILURE';

// Action creators
const SettingsRequest = () => createAction(SETTINGS_REQUEST);
const SettingsSuccess = (response: GetSettingsResponse) => createAction(SETTINGS_SUCCESS, response);
const SettingsFailure = (err: string) => createAction(SETTINGS_FAILURE, err);

export const fetchSettings = (
  apiKey: string,
  productId: string | undefined,
  contentLanguage: string,
  origin?: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(SettingsRequest());
  let params;
  if (productId) {
    params = {
      product_id: productId,
    };
  }

  const headers: Record<string, string> = {
    'x-api-key': apiKey,
    'accept-language': contentLanguage,
  };
  if (process.env.TEST_DOMAIN) {
    headers['origin'] = `https://${process.env.TEST_DOMAIN}`;
  } else if (origin) {
    headers['origin'] = origin;
  }

  return axios
    .get(`${config.apiUrl}/bookingwidgetsettings`, {
      params,
      headers,
    })
    .then((response) => {
      dispatch(SettingsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(SettingsFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof SettingsRequest>
  | ReturnType<typeof SettingsSuccess>
  | ReturnType<typeof SettingsFailure>;

const defaultSettings: GetSettingsResponse = {
  supplier_id: '',
  supplier_name: '',
  supplier_email: '',
  supplier_address: '',
  supplier_phone: '',
  terms_and_conditions: '',
  logo_url: '',
  icon_url: '',
  web_url: '',
  emergency_contact: '',
  operating_hours_rules: [],
  payment_settings: {
    accepted_guest_payment_types: [],
    accepted_guest_pob_payment_methods: [],
    accepted_pif_payment_currencies: [],
  },
  supported_languages: [],
  google_analytics_tag: '',
  ga4_google_analytics_tag: '',
  ua_google_analytics_tag: '',
  google_tag_manager_id: '',
  enable_product_list_page: true,
  agent_name: '',
  agent_logo_url: '',
  stripe_accounts: [],
  sns_ids: [],
  subscriptions: [],
  essential_pages: {
    about_us_settings: {
      visibility: false,
      title: '',
      key_visual: '',
      google_map_tag: '',
      other_company_profiles: [],
      messages: [],
    },
    faq_settings: {
      visibility: false,
      faqs: [],
    },
    covid19_settings: {
      visibility: false,
      introduction: '',
      precautions: [],
      custom_precautions: [],
      to_guests: [],
      custom_to_guests: [],
      other_message: '',
    },
  },
  private_marketplace_links: [],
  canonical_domain: '',
  guest_my_page_settings: {
    allow_viewing_reservation_details: false,
    allow_updating_reservation_info: false,
    allow_changing_reservation_parameters: false,
    allow_cancelling_reservation: false,
  },
  default_currency: '',
  default_timezone: '',
  source_language: '',
  enable_no_index: false,
};

// Selectors
export const selectAcceptedGuestPaymentTypes = createSelector(
  (state: ReduxState) => state.server.settings.all.payment_settings,
  (paymentSettings): Array<'PAY_ON_BOARD' | 'PAID_IN_FULL'> =>
    (paymentSettings && paymentSettings.accepted_guest_payment_types) || []
);

export const selectAcceptedGuestPaymentCurrencies = createSelector(
  (state: ReduxState) => state.server.settings.all.payment_settings,
  (paymentSettings): string[] =>
    paymentSettings?.accepted_pif_payment_currencies &&
    paymentSettings?.accepted_pif_payment_currencies.length > 0
      ? paymentSettings?.accepted_pif_payment_currencies
      : ['JPY', 'USD']
);

export const selectPMPEnabled = (state: ReduxState) =>
  !!state.server.settings.all.private_marketplace;

export const selectPMPLinks = (state: ReduxState) =>
  state.server.settings.all.private_marketplace_links;

const selectPMPLanguages = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplaceSettings) => {
    const lang = privateMarketplaceSettings?.language;
    return lang ? [lang] : [];
  }
);

export const selectLanguageOptions = createSelector(
  selectPMPEnabled,
  selectPMPLanguages,
  (state: ReduxState) => state.server.settings.all.supported_languages,
  (
    pmpEnabled: boolean,
    pmpLanguages: ContentLanguage[],
    supportedLanguages: ContentLanguage[]
  ): Array<{ name: string; iso: string }> => {
    if (pmpEnabled) {
      return pmpLanguages.map((lang) => languageOptionMap[lang]);
    }

    return (supportedLanguages || []).map((lang) => languageOptionMap[lang]);
  }
);

export const selectSettingsLoaded = createSelector(
  (state: ReduxState) => state.server.settings.all,
  (allSettings) => allSettings !== defaultSettings
);

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case SETTINGS_FAILURE:
      return action.payload;
    case SETTINGS_REQUEST:
    case SETTINGS_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: GetSettingsResponse = defaultSettings, action: Action) => {
  switch (action.type) {
    case SETTINGS_SUCCESS:
      return action.payload;
    case SETTINGS_FAILURE:
      return defaultSettings;
    default:
      return state;
  }
};

export interface SettingsState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
}

export default combineReducers({
  error,
  all,
});
