import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { GuidanceStampRally } from 'models/guidanceStampRally';
import { createAction } from 'ducks/actionHelpers';

const FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST = 'FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST';
const FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS = 'FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS';
const FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE = 'FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE';

const fetchDigitalGuidanceStampRallyRequest = () =>
  createAction(FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST);
const fetchDigitalGuidanceStampRallySuccess = (response: GuidanceStampRally) =>
  createAction(FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS, response);
const fetchDigitalGuidanceStampRallyFailure = (err: string) =>
  createAction(FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE, err);

export const fetchDigitalGuidanceStampRally = (
  apiKey: string,
  stampRallyId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalGuidanceStampRallyRequest());

  const path = `${config.apiUrl}/guidancestamprallies/${stampRallyId}`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchDigitalGuidanceStampRallySuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchDigitalGuidanceStampRallyFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchDigitalGuidanceStampRallyRequest>
  | ReturnType<typeof fetchDigitalGuidanceStampRallySuccess>
  | ReturnType<typeof fetchDigitalGuidanceStampRallyFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE:
      return action.payload;
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST:
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS:
      return '';
    default:
      return state;
  }
};

const stampRally = (state: GuidanceStampRally | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS:
      return action.payload;
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface DigitalGuidanceStampRallyState {
  error: ReturnType<typeof error>;
  stampRally: ReturnType<typeof stampRally>;
}

export default combineReducers({
  error,
  stampRally,
});
