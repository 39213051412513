import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18nClient from 'i18n-client';
import { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { CustomerTokenProvider } from 'components/Customer/CustomerTokenProvider';

import config from 'config';
import { wrapper, ReduxState } from 'ducks';

import { LiffProvider } from 'components/LiffProvider/LiffProvider';
import { LoadingProvider } from 'components/LoadingProvider/LoadingProvider';
import { LineAppLoading } from 'components/LineAppLoading/LineAppLoading';

import 'index.css';
import 'nprogress.css';

import 'react-day-picker/lib/style.css';

import 'components/DateFormPanel/DateFormPanel.css';
import 'components/DateFormPanel/PromotionPanel.css';
import 'components/DatePicker/DatePicker.css';
import 'components/MultiProductDateForm/GuestSelectModal.css';
import 'components/MultiProductDateForm/MultiProductDateForm.css';
import 'components/PrivateMarketplace/Home/Home.css';
import 'components/PrivateMarketplace/ProductCollection/ProductCollection.css';
import 'components/PrivateMarketplace/PopularTourList/PopularTourList.css';
import 'components/ProductContentsHeader/ProductContentsHeader.css';
import 'components/ProductContentsPane/AccordionList/accordionlist.css';
import 'components/ProductContentsPane/Highlights/highlights.css';
import 'components/ProductContentsPane/MediaCarousel/MediaCarousel.css';
import 'components/ProductContentsPane/OperationInfo/operationinfo.css';
import 'components/ProductContentsPane/productcontentspane.css';
import 'components/ProductContentsPane/ProductOutline/productoutline.css';
import 'components/ProductContentsPane/ProductOutline/Tabs/tabs.css';
import 'components/ProductContentsPane/PromotionDetails/PromotionDetails.css';
import 'components/ProductContentsPane/ServiceList/servicelist.css';
import 'components/ProductSummaryCard/productsummarycard.css';
import 'components/TagList/taglist.css';
import 'components/Sns/sns.css';
import 'components/Editor/Editor.css';
import 'components/LinkableTextArea/LinkableTextArea.css';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
    gtmEvent?: (e: any) => void;
    __CUSTOM_ANALYTICS_TAG__?: string;
    __CUSTOM_GA4_ANALYTICS_TAG__?: string;
    __CUSTOM_UA_ANALYTICS_TAG__?: string;
    __UA_DIGITAL_GUIDANCE_TAG__?: string;
    __GA4_DIGITAL_GUIDANCE_TAG__?: string;
  }
}

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
  window.gtag('config', config.googleAnalyticsTag, {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: window.document.title,
  });
  if (
    isDigitalGuidancePage(window.location.pathname) &&
    (window.__UA_DIGITAL_GUIDANCE_TAG__ || window.__GA4_DIGITAL_GUIDANCE_TAG__)
  ) {
    if (window.__UA_DIGITAL_GUIDANCE_TAG__) {
      window.gtag('config', window.__UA_DIGITAL_GUIDANCE_TAG__, {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: window.document.title,
      });
    }
    if (window.__GA4_DIGITAL_GUIDANCE_TAG__) {
      window.gtag('config', window.__GA4_DIGITAL_GUIDANCE_TAG__, {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: window.document.title,
      });
    }
  } else if (
    window.__CUSTOM_ANALYTICS_TAG__ ||
    window.__CUSTOM_GA4_ANALYTICS_TAG__ ||
    window.__CUSTOM_UA_ANALYTICS_TAG__
  ) {
    if (window.__CUSTOM_GA4_ANALYTICS_TAG__ || window.__CUSTOM_UA_ANALYTICS_TAG__) {
      if (window.__CUSTOM_GA4_ANALYTICS_TAG__) {
        window.gtag('config', window.__CUSTOM_GA4_ANALYTICS_TAG__, {
          page_location: window.location.href,
          page_path: window.location.pathname,
          page_title: window.document.title,
        });
      }
      if (window.__CUSTOM_UA_ANALYTICS_TAG__) {
        window.gtag('config', window.__CUSTOM_UA_ANALYTICS_TAG__, {
          page_location: window.location.href,
          page_path: window.location.pathname,
          page_title: window.document.title,
        });
      }
    } else {
      window.gtag('config', window.__CUSTOM_ANALYTICS_TAG__, {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: window.document.title,
      });
    }
  }
});
Router.events.on('routeChangeError', () => {
  NProgress.done();
});

const isDigitalGuidancePage = (pathname: string) => {
  return pathname.includes('guidance');
};

const WrappedApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const isEmbed = router.asPath.includes('/embed');

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    // (https://github.com/mui-org/material-ui/tree/master/examples/nextjs-with-typescript)
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const customAnalyticsTag = useSelector(
    (state: ReduxState) => state.server.settings.all.google_analytics_tag
  );
  const customGa4AnalyticsTag = useSelector(
    (state: ReduxState) => state.server.settings.all.ga4_google_analytics_tag
  );
  const customUaAnalyticsTag = useSelector(
    (state: ReduxState) => state.server.settings.all.ua_google_analytics_tag
  );
  const uaDigitalGuidanceTag = useSelector(
    (state: ReduxState) =>
      state.server.settings.all.digital_guidance_settings?.ua_google_analytics_tag
  );
  const ga4DigitalGuidanceTag = useSelector(
    (state: ReduxState) =>
      state.server.settings.all.digital_guidance_settings?.ga4_google_analytics_tag
  );

  React.useEffect(() => {
    if (customAnalyticsTag) {
      window.__CUSTOM_ANALYTICS_TAG__ = customAnalyticsTag;
    }
    if (customGa4AnalyticsTag) {
      window.__CUSTOM_GA4_ANALYTICS_TAG__ = customGa4AnalyticsTag;
    }
    if (customUaAnalyticsTag) {
      window.__CUSTOM_UA_ANALYTICS_TAG__ = customUaAnalyticsTag;
    }
    if (uaDigitalGuidanceTag) {
      window.__UA_DIGITAL_GUIDANCE_TAG__ = uaDigitalGuidanceTag;
    }
    if (ga4DigitalGuidanceTag) {
      window.__GA4_DIGITAL_GUIDANCE_TAG__ = ga4DigitalGuidanceTag;
    }
  }, [customAnalyticsTag, uaDigitalGuidanceTag, ga4DigitalGuidanceTag]);

  const googleTagManagerId = useSelector(
    (state: ReduxState) => state.server.settings.all.google_tag_manager_id
  );

  const enableNoIndex = useSelector(
    (state: ReduxState) => state.server.settings.all.enable_no_index
  );

  // Use a cloned i18n instance on server-side since i18nClient persists through different requests.
  const i18nInstance = typeof window === 'undefined' ? i18nClient.cloneInstance() : i18nClient;

  let analyticsScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${config.googleAnalyticsTag}', {linker:{accept_incoming:true}});`;
  if (isDigitalGuidancePage(router.asPath) && (uaDigitalGuidanceTag || ga4DigitalGuidanceTag)) {
    if (uaDigitalGuidanceTag) {
      analyticsScript =
        analyticsScript +
        `gtag('config', '${uaDigitalGuidanceTag}', {linker:{accept_incoming:true}});`;
    }
    if (ga4DigitalGuidanceTag) {
      analyticsScript =
        analyticsScript +
        `gtag('config', '${ga4DigitalGuidanceTag}', {linker:{accept_incoming:true}});`;
    }
  } else if (customAnalyticsTag || customGa4AnalyticsTag || customUaAnalyticsTag) {
    if (customUaAnalyticsTag || customGa4AnalyticsTag) {
      if (customUaAnalyticsTag) {
        analyticsScript =
          analyticsScript +
          `gtag('config', '${customUaAnalyticsTag}', {linker:{accept_incoming:true}});`;
      }
      if (customGa4AnalyticsTag) {
        analyticsScript =
          analyticsScript +
          `gtag('config', '${customGa4AnalyticsTag}', {linker:{accept_incoming:true}});`;
      }
    } else {
      analyticsScript =
        analyticsScript +
        `gtag('config', '${customAnalyticsTag}', {linker:{accept_incoming:true}});`;
    }
  }

  // TODO: redirect
  if (router.query['liff.state']) {
    return (
      <LiffProvider>
        <LineAppLoading />
      </LiffProvider>
    );
  }

  return (
    <CustomerTokenProvider>
      <I18nextProvider i18n={i18nInstance}>
        <LoadingProvider>
          <LiffProvider>
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1" />
              {/* Global Site Tag (gtag.js) - Google Analytics */}
              {enableNoIndex && (
                <>
                  <meta name="robots" content="noindex" />
                </>
              )}
              <script
                async={true}
                src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsTag}`}
              />
              {/*
          The following sends the first page view to GA. All subsequent
          page views will be handled by the `Router.onRouteChangeComplete`
          method we set up above.
        */}
              <script
                dangerouslySetInnerHTML={{
                  __html: analyticsScript,
                }}
              />
              {!isEmbed && googleTagManagerId && (
                <script
                  dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleTagManagerId}');`,
                  }}
                />
              )}
              {!isEmbed && googleTagManagerId && (
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
              function gtmEvent(e){dataLayer.push(e);}
              `,
                  }}
                />
              )}
            </Head>
            {!isEmbed && googleTagManagerId && (
              <noscript>
                <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
                  height="0"
                  width="0"
                  style={{
                    display: 'none',
                    visibility: 'hidden',
                  }}
                ></iframe>
              </noscript>
            )}
            <Component {...pageProps} />
          </LiffProvider>
        </LoadingProvider>
      </I18nextProvider>
    </CustomerTokenProvider>
  );
};

export default wrapper.withRedux(WrappedApp);
