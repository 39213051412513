import { applyMiddleware, combineReducers, createStore, Middleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { MakeStore, createWrapper } from 'next-redux-wrapper';
import logger from 'redux-logger';

import fares, { FaresState } from 'ducks/client/fares';
import productInstances, { ProductInstancesState } from 'ducks/client/productInstances';
import reservation, { ReservationState } from 'ducks/client/reservation';
import bookmarks, { loadBookmarks, BookmarksState } from 'ducks/client/bookmarks';
import hiddenPopupMessages, {
  loadHiddenPopupMessages,
  HiddenPopupMessagesState,
} from 'ducks/client/hiddenPopupMessages';
import mediaDownloadOrder, { MediaDownloadOrderState } from 'ducks/client/mediaDownloadOrder';
import visitedProductIds, {
  loadVisitedProductIds,
  VisitedProductsState,
} from 'ducks/client/visitedProducts';
import reviews, { ReviewsState } from 'ducks/client/reviews';
import waivers, { WaiversState } from 'ducks/client/waivers';
import coupons, { CouponsState } from 'ducks/client/coupons';
import surveys, { SurveysState } from 'ducks/client/surveys';
import server, { ServerState } from 'ducks/server';
import universal, { UniversalState } from 'ducks/universal';
import customer, { CustomerState } from 'ducks/client/customers';
import product, { ProductState } from 'ducks/client/product';
import availableProductSummaries, {
  AvailableProductSummariesState,
} from 'ducks/client/availableProductSummaries';

export interface ReduxState {
  productInstances: ProductInstancesState;
  reservation: ReservationState;
  server: ServerState;
  bookmarks: BookmarksState;
  hiddenPopupMessages: HiddenPopupMessagesState;
  mediaDownloadOrder: MediaDownloadOrderState;
  visitedProductIds: VisitedProductsState;
  reviews: ReviewsState;
  waivers: WaiversState;
  coupons: CouponsState;
  surveys: SurveysState;
  customer: CustomerState;
  product: ProductState;
  availableProductSummaries: AvailableProductSummariesState;

  fares: FaresState;

  universal: UniversalState;
}

const rootReducer = combineReducers({
  // Client reducers
  fares,
  productInstances,
  reservation,
  bookmarks,
  hiddenPopupMessages,
  mediaDownloadOrder,
  visitedProductIds,
  reviews,
  waivers,
  coupons,
  surveys,
  customer,
  product,
  availableProductSummaries,

  // Server reducers
  server,

  // Univeral reducers; these are used on both client and server
  universal,
});

// create a makeStore function
const middleware: Array<Middleware<any, Record<string, unknown>, any>> = [reduxThunk];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const makeStore: MakeStore<ReduxState> = () =>
  createStore(
    rootReducer,
    {
      bookmarks: typeof window !== 'undefined' ? loadBookmarks() : [],
      hiddenPopupMessages: typeof window !== 'undefined' ? loadHiddenPopupMessages() : [],
      visitedProductIds: typeof window !== 'undefined' ? loadVisitedProductIds() : [],
    },
    applyMiddleware(...middleware)
  );
export const wrapper = createWrapper<ReduxState>(makeStore, { debug: false });
