import { useState } from 'react';

import { LoadingContext } from 'contexts/LoadingContext';
import { LineAppLoading } from 'components/LineAppLoading/LineAppLoading';

interface Props {
  children: React.ReactNode;
}

export function LoadingProvider({ children }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const value = { loading, setLoading };
  return (
    <LoadingContext.Provider value={value}>
      {children}
      {loading && <LineAppLoading />}
    </LoadingContext.Provider>
  );
}
