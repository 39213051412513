import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import {
  RedeemStampRallyGiftRequest,
  GuidanceStampRallyGiftRedemption,
} from 'models/guidanceStampRally';

// Actions
const REDEEM_STAMP_RALLY_GIFT_REQUEST = 'REDEEM_STAMP_RALLY_GIFT_REQUEST';
export const REDEEM_STAMP_RALLY_GIFT_SUCCESS = 'REDEEM_STAMP_RALLY_GIFT_SUCCESS';
const REDEEM_STAMP_RALLY_GIFT_FAILURE = 'REDEEM_STAMP_RALLY_GIFT_FAILURE';

// Action creators
const redeemStampRallyGiftRequest = (payload: RedeemStampRallyGiftRequest) =>
  createAction(REDEEM_STAMP_RALLY_GIFT_REQUEST, payload);
export const redeemStampRallyGiftSuccess = (payload: GuidanceStampRallyGiftRedemption) =>
  createAction(REDEEM_STAMP_RALLY_GIFT_SUCCESS, payload);
const redeemStampRallyGiftFailure = (payload: string) =>
  createAction(REDEEM_STAMP_RALLY_GIFT_FAILURE, payload);

export const redeemStampRallyGift = (
  apiKey: string,
  req: RedeemStampRallyGiftRequest
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  dispatch(redeemStampRallyGiftRequest(req));
  return axios
    .post(`${config.apiUrl}/reservations/${req.reservation_id}/redeemstamprallygift`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(redeemStampRallyGiftSuccess(response.data));
    })
    .catch((err) => {
      dispatch(redeemStampRallyGiftFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof redeemStampRallyGiftRequest>
  | ReturnType<typeof redeemStampRallyGiftSuccess>
  | ReturnType<typeof redeemStampRallyGiftFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case REDEEM_STAMP_RALLY_GIFT_FAILURE:
      return action.payload;
    case REDEEM_STAMP_RALLY_GIFT_REQUEST:
    case REDEEM_STAMP_RALLY_GIFT_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case REDEEM_STAMP_RALLY_GIFT_REQUEST:
      return true;
    case REDEEM_STAMP_RALLY_GIFT_SUCCESS:
    case REDEEM_STAMP_RALLY_GIFT_FAILURE:
      return false;
    default:
      return state;
  }
};

export interface StampsState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
}

export default combineReducers({
  error,
  loading,
});
