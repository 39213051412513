import { WithTranslation } from 'react-i18next';

import { GuestType, Product } from 'models/product';
import { Reservation } from 'models/reservation';
import { Price } from 'lib/util/productShape';
import { getGuestTypesUsedInProduct } from 'lib/util/getGuestTypesUsedInProduct';

export const printGuestType = (g: GuestType, t: WithTranslation['t']) =>
  g.minimum_age && g.maximum_age
    ? t('{{guestTitle}} (ages {{guestMinAge}}-{{guestMaxAge}})', {
        guestTitle: g.title,
        guestMinAge: g.minimum_age,
        guestMaxAge: g.maximum_age,
      })
    : g.minimum_age
    ? t('{{guestTitle}} (ages {{guestMinAge}}+)', {
        guestTitle: g.title,
        guestMinAge: g.minimum_age,
      })
    : g.maximum_age
    ? t('{{guestTitle}} (ages {{guestMaxAge}} & under)', {
        guestTitle: g.title,
        guestMaxAge: g.maximum_age,
      })
    : g.title;

export const printPriceTitle = (price: Price, t: WithTranslation['t']): string => {
  if (!price.unitTitle) {
    return t('Per-Booking');
  }

  return price.minimumAge && price.maximumAge
    ? t('{{guestTitle}} (ages {{guestMinAge}}-{{guestMaxAge}})', {
        guestTitle: price.unitTitle,
        guestMinAge: price.minimumAge,
        guestMaxAge: price.maximumAge,
      })
    : price.minimumAge
    ? t('{{guestTitle}} (ages {{guestMinAge}}+)', {
        guestTitle: price.unitTitle,
        guestMinAge: price.minimumAge,
      })
    : price.maximumAge
    ? t('{{guestTitle}} (ages {{guestMaxAge}} & under)', {
        guestTitle: price.unitTitle,
        guestMaxAge: price.maximumAge,
      })
    : price.unitTitle;
};

export const isRequiredGuestType = (g: GuestType): boolean => {
  return g.key === 'Adult' || g.key === '大人';
};

export const getGuestTypeFromGuestKey = (
  product: Product | null,
  guestTypeKey: string,
  t: WithTranslation['t']
): GuestType | null => {
  if (!product) {
    return null;
  }

  const allGuestTypes = getGuestTypesUsedInProduct(product, t);
  const matchingGuestType = allGuestTypes.find((guestType) => guestType.key === guestTypeKey);

  return matchingGuestType || null;
};

export const productHasAddOns = (product: Product | null): boolean => {
  return !!(product && product.add_ons && product.add_ons.length !== 0);
};

export const productHasTransportations = (product: Product | null): boolean => {
  return !!(product && product.transportations && product.transportations.length !== 0);
};

export const hasProductDiscounts = (product: Product): boolean => {
  return Object.keys(getProductDiscounts(product)).length > 0;
};

const DISCOUNT_TAG = 'HP discount';
export const getProductDiscounts = (product: Product | null): { [key: string]: string } => {
  if (product && product.product_tags.includes(DISCOUNT_TAG)) {
    return {
      大人: 'AUD5.00',
      学生: 'AUD10.00',
    };
  }

  return {};
};

export const getGuestAddOns = (
  guestSelectedAddOns: { [key: string]: string[][] },
  guestTypeKey: string,
  guestNumber: number
) => {
  return (
    guestSelectedAddOns &&
    guestSelectedAddOns[guestTypeKey] &&
    guestSelectedAddOns[guestTypeKey][guestNumber]
  );
};

// given JPY36000, return 'JPY'
export const getCurrencyCodeFromMoneyString = (moneyString: string): string => {
  return (moneyString && moneyString.substring(0, 3)) || '';
};

// given JPY36000, return 36000 as a number
export const getAmountFromMoneyString = (moneyString: string): number => {
  const valueAsString = moneyString && moneyString.substring(3);

  if (!valueAsString) {
    return 0;
  }

  return parseFloat(valueAsString);
};

export const showTransportation = (product: Product | null): boolean => {
  const numOfCheckin = (product?.checkin || []).length;
  const numOfCheckout = (product?.checkout || []).length;
  const numOfFreeTransportation = (product?.transportations || []).filter(
    (t) => t.service_type === 'FREE'
  ).length;
  const numOfPaidTransportation = (product?.transportations || []).filter(
    (t) => t.service_type === 'PAID'
  ).length;

  if (
    numOfCheckin === 0 &&
    numOfCheckout === 0 &&
    numOfFreeTransportation === 1 &&
    numOfPaidTransportation === 0
  ) {
    return false;
  }

  if (
    (numOfCheckin === 1 || numOfCheckout === 1) &&
    numOfFreeTransportation === 0 &&
    numOfPaidTransportation === 0
  ) {
    return false;
  }

  return true;
};

export const isFullPaymentCondition = (reservation: Reservation | null): boolean => {
  if (reservation === null) {
    return false;
  }

  if (reservation?.billing_info?.invoice_status !== 'INVOICE_IN_PROGRESS') {
    return false;
  }

  return true;
};

export const isAdjustPaymentCondition = (reservation: Reservation | null): boolean => {
  if (reservation === null) {
    return false;
  }

  if (isFullPaymentCondition(reservation)) {
    return false;
  }

  if (reservation?.billing_info?.invoice_status !== 'INVOICE_CHARGE_PENDING') {
    return false;
  }

  return true;
};
