import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { ListProductsResponse, ProductSummary } from '../../models/product';
import { createAction } from '../actionHelpers';

// Actions
const PRODUCT_SUMMARIES_REQUEST = 'PRODUCT_SUMMARIES_REQUEST';
const PRODUCT_SUMMARIES_SUCCESS = 'PRODUCT_SUMMARIES_SUCCESS';
const PRODUCT_SUMMARIES_FAILURE = 'PRODUCT_SUMMARIES_FAILURE';

// Action creators
const productSummariesRequest = () => createAction(PRODUCT_SUMMARIES_REQUEST);
const productSummariesSuccess = (response: ListProductsResponse) =>
  createAction(PRODUCT_SUMMARIES_SUCCESS, response);
const productSummariesFailure = (err: string) => createAction(PRODUCT_SUMMARIES_FAILURE, err);

export const fetchProductSummaries = (
  apiKey: string,
  contentLanguage: string,
  includePrices = false,
  ignoreBlacklistedLanguages = false
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  dispatch(productSummariesRequest());
  return axios
    .get(`${config.apiUrl}/products`, {
      params: {
        ignore_blacklisted_languages: ignoreBlacklistedLanguages,
        include_prices: includePrices,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      console.log(response.data);
      dispatch(productSummariesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(productSummariesFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof productSummariesRequest>
  | ReturnType<typeof productSummariesSuccess>
  | ReturnType<typeof productSummariesFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case PRODUCT_SUMMARIES_FAILURE:
      return action.payload;
    case PRODUCT_SUMMARIES_REQUEST:
    case PRODUCT_SUMMARIES_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: ProductSummary[] = [], action: Action) => {
  switch (action.type) {
    case PRODUCT_SUMMARIES_SUCCESS:
      return action.payload.products;
    case PRODUCT_SUMMARIES_REQUEST:
    case PRODUCT_SUMMARIES_FAILURE:
      return [];
    default:
      return state;
  }
};

// Sum review_count field for products
const reviewCount = (state = 0, action: Action) => {
  switch (action.type) {
    case PRODUCT_SUMMARIES_SUCCESS:
      // Sum review_count field for products
      return action.payload.products.reduce((acc, product) => {
        const reviewCount = product.review_count ?? 0;
        return acc + reviewCount;
      }, 0);
    case PRODUCT_SUMMARIES_REQUEST:
    case PRODUCT_SUMMARIES_FAILURE:
      return 0;
    default:
      return state;
  }
};

// Get average rating for all products
export const reviewRating = (state = 0, action: Action): number => {
  switch (action.type) {
    case PRODUCT_SUMMARIES_SUCCESS: {
      const ratingSum = action.payload.products.reduce((acc, product) => {
        const reviewRating = product.review_rating ?? 0;
        const reviewCount = product.review_count ?? 0;
        return acc + reviewRating * reviewCount;
      }, 0);
      const reviewCount = action.payload.products.reduce((acc, product) => {
        const cnt = product.review_count ?? 0;
        return acc + cnt;
      }, 0);

      if (reviewCount === 0) {
        return 0;
      }

      return ratingSum / reviewCount;
    }
    case PRODUCT_SUMMARIES_REQUEST:
    case PRODUCT_SUMMARIES_FAILURE:
      return 0;
    default:
      return state;
  }
};

export interface ProductSummariesState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
  reviewCount: ReturnType<typeof reviewCount>;
  reviewRating: ReturnType<typeof reviewRating>;
}

export default combineReducers({
  error,
  all,
  reviewCount,
  reviewRating,
});
