import { AnyAction, combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import reviews, { ReviewsState } from './universal/reviews';

export type UniversalState = {
  reviews: ReviewsState;
};

const reducer = combineReducers({
  reviews,
});

const universal = (state: UniversalState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.universal,
      };
    default:
      return reducer(state, action);
  }
};

export default universal;
