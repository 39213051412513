import * as React from 'react';
import { useRouter } from 'next/router';

import type Liff from '@line/liff';

import { LiffContext } from 'contexts/LiffContext';

interface Props {
  children: React.ReactNode;
}

export const LiffProvider = ({ children }: Props) => {
  const router = useRouter();
  const [liff, setLiff] = React.useState<typeof Liff | null>(null);

  React.useEffect(() => {
    if (
      router.asPath.match(/checkout/) ||
      router.asPath.match(/add/) ||
      router.asPath.match(/tickets/) ||
      router.asPath.match(/voucherauth/) ||
      router.asPath.match(/guidanceauth/) ||
      router.asPath.match(/reservationauth/) ||
      router.asPath.match(/reservations/) ||
      router.asPath.match(/guidance/) ||
      router.asPath.match(/surveyauth/) ||
      router.asPath.match(/survey/)
    ) {
      if (!liff || !liff.isLoggedIn()) {
        (async () => {
          const liff = (await import('@line/liff')).default;
          await liff.init({ liffId: process.env.NEXT_PUBLIC_LIFF_ID ?? '' });
          setLiff(liff);
          if (!liff.isLoggedIn()) {
            liff.login({
              redirectUri: window.location.protocol + '//' + window.location.host + router.asPath,
            });
          }
        })();
      }
    }
  }, [router.asPath]);

  return <LiffContext.Provider value={liff}>{children}</LiffContext.Provider>;
};
