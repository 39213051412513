import * as React from 'react';

import styles from './LineAppLoading.module.css';

export const LineAppLoading: any = () => {
  return (
    <div className={styles.loading}>
      <img src="/static/images/ic_line_spinner_light.svg" />
    </div>
  );
};
